/* eslint-disable no-await-in-loop */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from 'react'
import { graphql, navigate } from 'gatsby'
import { useSpring, animated } from 'react-spring'
import Img from 'gatsby-image/withIEPolyfill'

import styled, { themeGet } from '@style'
import { Layout } from '@components/Layout'
import { Hero } from '@components/Hero'
import { NewsletterSection } from '@components/NewsletterSection'
import { Box, Flex } from '@components/Grid'
import { SEO } from '@components/SEO'
import { Text } from '@components/Text'
import {
  H1,
  H2,
  H4,
  Topline,
  StrongDarkBackgroundWrapper,
} from '@components/Heading'
import { Button } from '@components/Button'
import { useScrollToElement } from '@hooks/useScrollToElement'

const HeroGradient = styled.div`
  position: absolute;
  background: ${props => props.gradient};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const AnimatedBlobb = styled(animated.div)`
  display: block;
  min-height: 80px;
  min-width: 80px;
  width: 20vw;
  height: 20vw;
  max-height: 200px;
  max-width: 200px;
  position: absolute;
  top: 20px;
  left: 0;
  transform: translate3d(-50%, -50%, 0);
  z-index: -1;
  /* stylelint-disable */
  background: linear-gradient(
    210deg,
    hsla(255, 100%, 100%, 0.1),
    hsla(255, 100%, 100%, 1)
  );
  /* stylelint-enable */

  @media screen and (min-width: ${themeGet('breakpoints.sm')}) {
    top: 40px;
    left: 50px;
  }

  @media screen and (min-width: ${themeGet('breakpoints.md')}) {
    top: 60px;
    left: 70px;
  }

  @media screen and (min-width: ${themeGet('breakpoints.lg')}) {
    top: 70px;
    left: 90px;
  }
`

const IndexPage = ({ data: { content } }) => {
  const [isKeynotesHovered, setKeynotesHovered] = React.useState(false)
  const [isTrainingsHovered, setTrainingsHovered] = React.useState(false)
  const [isRetreatsHovered, setRetreatsHovered] = React.useState(false)
  const keynotesBlob = useSpring({
    config: { duration: 900 },
    from: {
      borderRadius: '76% 24% 35% 65% / 45% 70% 30% 55% ',
    },
    to: async next => {
      while (isKeynotesHovered) {
        await next({
          borderRadius: '60% 40% 54% 46% / 40% 65% 35% 60%',
        })
        await next({
          borderRadius: '78% 22% 63% 37% / 34% 34% 66% 66%',
        })
        await next({
          borderRadius: '51% 49% 63% 37% / 56% 42% 58% 44%',
        })
        await next({
          borderRadius: '40% 60% 42% 58% / 41% 51% 49% 59%',
        })
        await next({
          borderRadius: '33% 67% 70% 30% / 30% 30% 70% 70%',
        })
      }
    },
  })

  const trainingsBlob = useSpring({
    config: { duration: 900 },
    from: {
      borderRadius: '33% 67% 70% 30% / 30% 30% 70% 70%',
    },
    to: async next => {
      while (isTrainingsHovered) {
        await next({
          borderRadius: '60% 40% 54% 46% / 40% 65% 35% 60%',
        })
        await next({
          borderRadius: '78% 22% 63% 37% / 34% 34% 66% 66%',
        })
        await next({
          borderRadius: '51% 49% 63% 37% / 56% 42% 58% 44%',
        })
        await next({
          borderRadius: '40% 60% 42% 58% / 41% 51% 49% 59%',
        })
        await next({
          borderRadius: '76% 24% 35% 65% / 45% 70% 30% 55% ',
        })
      }
    },
  })

  const retreatsBlob = useSpring({
    config: { duration: 900 },
    from: {
      borderRadius: '51% 49% 63% 37% / 56% 42% 58% 44%',
    },
    to: async next => {
      while (isRetreatsHovered) {
        await next({
          borderRadius: '60% 40% 54% 46% / 40% 65% 35% 60%',
        })
        await next({
          borderRadius: '78% 22% 63% 37% / 34% 34% 66% 66%',
        })
        await next({
          borderRadius: '33% 67% 70% 30% / 30% 30% 70% 70%',
        })
        await next({
          borderRadius: '40% 60% 42% 58% / 41% 51% 49% 59%',
        })
        await next({
          borderRadius: '76% 24% 35% 65% / 45% 70% 30% 55% ',
        })
      }
    },
  })

  // For scroll-to-button
  const targetRef = React.useRef(null)
  const { scrollToTarget } = useScrollToElement()

  return (
    <Layout>
      <SEO
        metadata={{
          title: ``,
          description: content.seo.seoDescription,
          pathname: `/`,
        }}
      />
      <Box
        width="100%"
        minHeight={['45vh', '55vh']}
        p={[6, 7, 8]}
        pl={[6, 7, 8, 9]}
        display="grid"
      >
        <Img
          fluid={content.hero.backgroundImage.asset.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
          }}
        />
        <HeroGradient gradient="linear-gradient(30deg, hsla(0, 0%, 0%, 0.6) 15%, hsla(0, 0%, 0%, 0.6) 40%, hsla(217, 33%, 22%, 0.0) 100%)" />
        <Hero.Shape shape="homePage" color="white" />
        <Flex flexDirection="column" justifyContent="flex-end">
          <Topline css={{ color: 'white' }}>{content._rawHero.topline}</Topline>
          <StrongDarkBackgroundWrapper>
            <Box
              css={{
                '& > div': { display: 'inline-block' },
                h1: { textShadow: '0 1px 10px hsla(0, 0%, 0%, 0.3)' },
              }}
            >
              <H1
                css={{
                  color: 'white',
                  fontSize: 'min(max(28px, 10vw), 80px)',
                }}
                content={content._rawHero.heading}
              />
            </Box>
          </StrongDarkBackgroundWrapper>
          <Box mt={4} mb={[4, 8, 8, 9]}>
            <Button onClick={() => scrollToTarget(targetRef.current)}>
              {content._rawHero.cta.label}
              {content._rawHero.cta.showArrow && <Hero.Button.Arrow />}
            </Button>
          </Box>
        </Flex>
      </Box>

      <Box ref={targetRef} as="section" bg="white" p={[6, 7, 9]} py={[8, 9]}>
        <Box maxWidth="700px" mx="auto">
          <Topline>{content.firstSectionTopline}</Topline>
          <H2 content={content._rawFirstSectionHeading} />
          <Box pl={[0, 6]} mt={[5, 6]} pb={[5]}>
            <Text mb={[5]} content={content._rawFirstSectionText} />
          </Box>
        </Box>
      </Box>

      <Box as="section" zIndex="1" p={[6, 7, 9]} py={[8, 9]} bg="blue.100">
        <Box maxWidth="700px" mx="auto">
          <Topline>{content.secondSectionTopline}</Topline>
          <H2 content={content._rawSecondSectionHeading} />
          <Box pl={[0, 6]} mt={[5, 6]} pb={[5]}>
            <Text mb={[5]} content={content._rawSecondSectionText} />
          </Box>
        </Box>

        <Flex flexDirection={['column', null, null, 'row']} pl={[4]}>
          <Flex
            flexDirection="column"
            width={['100%', null, null, '33.33%']}
            mt={[7, 8]}
            pl={[0, 6]}
            pr={[0, 6]}
            onMouseEnter={() => setKeynotesHovered(true)}
            onMouseLeave={() => setKeynotesHovered(false)}
          >
            <H4 content={content._rawFirstServiceHeading} />
            <Text mt={[3]}>{content.firstServiceText}</Text>
            <Box mt="auto">
              <Button mt={[3]} onClick={() => navigate('/expertise/keynotes')}>
                {content.firstServiceCTA.label}
                {content.firstServiceCTA.showArrow && <Button.Arrow />}
              </Button>
            </Box>
            <AnimatedBlobb style={keynotesBlob} />
          </Flex>

          <Flex
            flexDirection="column"
            width={['100%', null, null, '33.33%']}
            mt={[7, 8]}
            pl={[0, 6]}
            pr={[0, 6]}
            onMouseEnter={() => setTrainingsHovered(true)}
            onMouseLeave={() => setTrainingsHovered(false)}
          >
            <H4 content={content._rawSecondServiceHeading} />
            <Text mt={[3]}>{content.secondServiceText}</Text>
            <Box mt="auto">
              <Button mt={[3]} onClick={() => navigate('/expertise/trainings')}>
                {content.secondServiceCTA.label}
                {content.secondServiceCTA.showArrow && <Button.Arrow />}
              </Button>
            </Box>
            <AnimatedBlobb style={trainingsBlob} />
          </Flex>

          <Flex
            flexDirection="column"
            width={['100%', null, null, '33.33%']}
            mt={[7, 8]}
            pl={[0, 6]}
            pr={[0, 6]}
            onMouseEnter={() => setRetreatsHovered(true)}
            onMouseLeave={() => setRetreatsHovered(false)}
          >
            <H4 content={content._rawThirdServiceHeading} />
            <Text mt={[3]}>{content.thirdServiceText}</Text>
            <Box mt="auto">
              <Button mt={[3]} onClick={() => navigate('/expertise/retreats')}>
                {content.thirdServiceCTA.label}
                {content.thirdServiceCTA.showArrow && <Button.Arrow />}
              </Button>
            </Box>
            <AnimatedBlobb style={retreatsBlob} />
          </Flex>
        </Flex>
      </Box>
      <NewsletterSection />
    </Layout>
  )
}

export const query = graphql`
  query HomePage {
    content: sanityHomePageSingleton {
      seo {
        seoDescription
        seoTitle
      }
      _rawHero
      hero {
        backgroundImage {
          asset {
            fluid(maxWidth: 1400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      firstSectionTopline
      _rawFirstSectionHeading
      _rawFirstSectionText
      secondSectionTopline
      _rawSecondSectionHeading
      _rawSecondSectionText(resolveReferences: { maxDepth: 10 })
      _rawFirstServiceHeading
      firstServiceText
      firstServiceCTA {
        label
        showArrow
      }
      _rawSecondServiceHeading
      secondServiceText
      secondServiceCTA {
        label
        showArrow
      }
      _rawThirdServiceHeading
      thirdServiceText
      thirdServiceCTA {
        label
        showArrow
      }
    }
  }
`

export default IndexPage
